import React from "react";
import { Link } from "react-router-dom";
import logo from "../../Assets/Images/logo.png";
import googleDownload from "../../Assets/Images/google-playstore.svg";
import appleDownload from "../../Assets/Images/apple-appstore.svg";

const Footer = () => {
  return (
    <div className="bg-custom-3">
      <div className="container pt-4 mt-3">
        <div className="row">
          <div className="col-12 col-lg-4">
          <Link
          type="button"
          className="fs-4 border-0 fw-bolder bg-transparent navbar-brand txt-custom-1"
          id="brand-name"
          to='/'
        >
          <img src={logo} alt="Download on the App Store" width="200" height="30" />
        </Link>
            <p className="my-5 fs-6" style={{ textAlign: "justify" }}>
              Medi-Aide is perfect for elderly patients, busy professionals, and people in remote areas seeking quality healthcare services at their convenience.
            </p>
            {/* <h3 className="txt-custom-1 mb-2">
              Download the apps here...
            </h3> */}

            <a href="/" target="_blank">
              <img src={appleDownload} alt="Download on the App Store" width="200" height="60" />
            </a>

            <a href="/" target="_blank">
              <img src={googleDownload} alt="Get it on Google Play" width="200" height="85" />
            </a>

          </div>

          <div className="col-12 col-lg-8 mb-3">
            <div className="row">
              <div className="col-6 col-lg-4 pt-4">
                <nav className="nav flex-column gap-3">
                  <h5 className="ps-1 mb-3">Product</h5>
                  <button
                    type="button"
                    className="btn border-0 text-muted text-start bg-transparent
                  "
                  >
                    MEDI-AIDE
                  </button>
                  <button
                    type="button"
                    className="btn border-0 text-muted text-start bg-transparent"
                  >
                    Appointment
                  </button>
                </nav>
              </div>
              <div className="col-6 col-lg-4 pt-4">
                <nav className="nav flex-column gap-3">
                  <h5 className="ps-1 mb-3">Support</h5>
                  <button
                    type="button"
                    className="btn border-0 text-muted text-start bg-transparent
                  "
                  >
                    Help Centre
                  </button>
                  <button
                    type="button"
                    className="btn border-0 text-muted text-start bg-transparent"
                  >
                    FAQ
                  </button>
                  <button
                    type="button"
                    className="btn border-0 text-muted text-start bg-transparent"
                  >
                    Contact
                  </button>
                </nav>
              </div>
              <div className="col-6 col-lg-4 pt-4">
                <nav className="nav flex-column gap-3">
                  <h5 className="ps-1 mb-3">Company</h5>
                  <button
                    type="button"
                    className="btn border-0 text-muted text-start bg-transparent
                  "
                  >
                    Terms & Condition
                  </button>
                  <button
                    type="button"
                    className="btn border-0 text-muted text-start bg-transparent"
                  >
                    Privacy Policy
                  </button>
                  <button
                    type="button"
                    className="btn border-0 text-muted text-start bg-transparent"
                  >
                    About
                  </button>
                  <button
                    type="button"
                    className="btn border-0 text-muted text-start bg-transparent"
                  >
                    Why MEDI-AIDE?
                  </button>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div>
          <p className="text-muted mt-5">© 2023 MEDI-AIDE. All rights reserved</p>
        </div>
        <div style={{ height: "5vh" }}>

        </div>
      </div>
    </div>
  );
};

export default Footer;

import React from "react";
import { Link } from "react-router-dom";
import Links from "../../Components/Links";
import logo from "../../Assets/Images/logo.png";

const Header = () => {
  return (
    <nav className="navbar navbar-expand-lg mb-2 sticky-top bg-custom-3">
      <div className="container">
        <Link
          type="button"
          className="fs-4 border-0 fw-bolder bg-transparent navbar-brand txt-custom-1"
          id="brand-name"
          to="/"
        >
          <img
            src={logo}
            alt="Download on the App Store"
            width="200"
            height="30"
          />
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#header"
          aria-controls="header"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="header">
          <Links />

          <ul className="navbar-nav flex-shrink-1 justify-content-lg-center flex-row gap-3">
            <li className="nav-item">
              <a
                className="nav-link px-4 py-1 my-1 fw-bolder rounded bg-transparent"
                href="https://app.medi-aide.com/#/login/"
              >
                Login
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link px-4 py-1 my-1 fw-bold border-0 rounded-5 text-white bg-custom-1"
                href="https://app.medi-aide.com/#/register/"
              >
                Register
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;

import { Link } from "react-router-dom";
import React, { useState } from 'react';

const HoverList = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      onMouseEnter={handleHover}
      onMouseLeave={handleLeave}
      style={{ position: 'relative', display: 'inline-block' }}
    >
      <Link
        to=""
        className="nav-link fw-bold border-0 bg-transparent text-dark"
      >
        Services{' '}
        <span style={{ fontSize: '0.8em' }}>{isHovered ? '▲' : '▼'}</span>
      </Link>

      {isHovered && (
        <div
          style={{
            backgroundColor: 'white',
            border: '1px solid black',
            padding: '10px',
            position: 'absolute',
            top: '100%',
            left: 0,
            borderRadius: '5px',
            width: '200px'
          }}
          onMouseEnter={handleHover}
          onMouseLeave={handleLeave}
        >
          <li className="nav-item pe-2">
            <Link className="nav-link fw-bold border-0 bg-transparent text-dark" to='/'>
              Virtual Consultations
            </Link>
          </li>
          <li className="nav-item pe-2">
            <Link
              to="/"
              className="nav-link fw-bold border-0 bg-transparent text-dark"
            >
              Laboratory Tests
            </Link>
          </li>
          <li className="nav-item pe-2">
            <Link
              to="/"
              className="nav-link fw-bold border-0 bg-transparent text-dark"
            >
              Pharmacy
            </Link>
          </li>
          <li className="nav-item pe-2">
            <Link
              to="/"
              className="nav-link fw-bold border-0 bg-transparent text-dark"
            >
              Home Care
            </Link>
          </li>
        </div>
      )}
    </div>
  );
};

export default HoverList;


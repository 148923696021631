import React from "react";
import { Link } from "react-router-dom";

const Home = () => {
  // recommit stats
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <ul className="nav nav-tabs justify-content-center">
            <li className="nav-item">
              <Link className="nav-link active" aria-current="page" href="#">
                DashBoard
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" href="#">
                Template
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" href="#">
                Settings
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Home;

import Signup from "./Pages/Authentication/Signup";
import Header from "./Layouts/Header";

import Signin from "./Pages/Authentication/Signin";
import Footer from "./Layouts/Footer";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Landing from "./Pages/Landing";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Services from "./Pages/Services";
import Faq from "./Pages/Faq";

function App() {
  return (
    <Router>
      
      <Switch>
        
        <Route path="/" exact>
          <Header />
          <Landing />
          <Footer />
        </Route>
        <Route path="/about" exact>
          <Header />
          <About />
          <Footer />
        </Route>
        <Route path="/services" exact>
          <Header />
          <Services />
          <Footer />
        </Route>
        <Route path="/faq" exact>
          <Header />
          <Faq />
          <Footer />
        </Route>
        
        <Route path="/signup" exact>
          <Signup />
        </Route>
        <Route path="/signin" exact>
          <Signin />
        </Route>
        <Route path="/home" exact>
          <Home />
        </Route>
      </Switch>
      
    </Router>
  );
}

export default App;

import { Link } from "react-router-dom";
import HoverList from '../Components/ServiceMenu';

const Links = () => {
  return (
    <>
      <ul className="navbar-nav justify-content-center flex-grow-1">
        
        <li className="nav-item pe-2">
          
            <HoverList />
          
        </li>
        <li className="nav-item pe-2">
          <Link
            to="/"
            className="nav-link fw-bold border-0 bg-transparent text-dark"
          >
            Partnerships
          </Link>
        </li>
        <li className="nav-item pe-2">
          <Link
            to="/"
            className="nav-link fw-bold border-0 bg-transparent text-dark"
          >
            About Us
          </Link>
        </li>
        <li className="nav-item pe-2">
          <Link
            to="/"
            className="nav-link fw-bold border-0 bg-transparent text-dark"
          >
            Contact Us
          </Link>
        </li>
        
      </ul>
    </>
  );
};

export default Links;

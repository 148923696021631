

function ServiceCard({ title, description }) {
  return (
    <div className="col-12 col-md-6 col-lg-4 my-2 px-2">
      <div className="text-center">
        <h2 className="fw-bold m-0">{title}</h2>
        <p className="fs-6 m-0 lead">
          {description}
        </p>
      </div>
    </div>
  );
}

const Services = () => {
  const servicesData = [
    {
      title: 'Find Health Providers',
      description: 'Discover a wide range of healthcare professionals in your area and easily find the right provider for your needs.'
    },
    {
      title: 'Schedule Consultation',
      description: 'Book appointments and consultations with doctors, specialists, and other healthcare professionals at your convenience.'
    },
    {
      title: 'Home Care Services',
      description: 'Access home care services, including nursing care, medical equipment, and assistance with daily activities, all from the comfort of your home.'
    },
    {
      title: 'Telemedicine',
      description: 'Experience virtual healthcare consultations through video calls or chat with healthcare professionals from anywhere, saving you time and effort.'
    },
    {
      title: 'Medical Records Management',
      description: 'Efficiently manage and access your medical records, including test results, prescriptions, and treatment history, in a secure and centralized platform.'
    },
    {
      title: 'Health Tips and Information',
      description: 'Stay informed and receive valuable health tips, articles, and educational resources to empower you in making informed decisions about your health.'
    }
  ];

  return (
    <div className="container">
      <div className="text-center mt-4">
        <h1 className="fw-bold m-0 txt-custom-1">Our Services</h1>
        <p className="fs-6 lead m-0">
          Our <span className="txt-custom-1">experienced</span> and <span className="txt-custom-1">dedicated</span> staff provide these services with a smile.
        </p>
      </div>
      <div className="row my-5">
        {servicesData.map((service) => (
          <ServiceCard
            key={service.title}
            title={service.title}
            description={service.description}
          />
        ))}
      </div>
    </div>
  );
};

export default Services;

import React from "react";
import image1 from "../../../Assets/Images/maleDoctor.svg";
import { Link } from "react-router-dom";

const Signin = () => {
  return (
    <div className="container-fluid vh-100">
      <div className="row">
        <div className="col-12 col-lg-6 h-100 bg-custom-3">
          <div className="module-box">
            <h2 className="txt-custom-1">Login</h2>
            <form>
              <div className="form-floating mb-3">
                <input
                  type="email"
                  className="form-control mb-2 border-top-0 border-start-0 border-end-0"
                  id="floatingInput"
                  placeholder="name@example.com"
                />
                <label for="floatingInput">Email address</label>
              </div>
              <div className="form-floating">
                <input
                  type="password"
                  className="form-control mb-2 border-top-0 border-start-0 border-end-0"
                  id="floatingPassword"
                  placeholder="Password"
                />
                <label for="floatingPassword">Password</label>
              </div>
              <span className="d-block text-center my-2">
                <button
                  type="button"
                  className="text-muted bg-transparent border-0"
                >
                  Forgot Password?
                </button>
              </span>
              <span className="d-block text-center mb-3">
                Don't have an account?
                <Link
                  type="button"
                  className="text-decoration-none ms-1 fw-bold bg-transparent txt-custom-1"
                  to="signup"
                >
                  Register
                </Link>
              </span>
              <span className="d-block text-center mb-4">
                <button
                  type="submit"
                  className="btn btn-lg w-100 bg-custom-1 border-0 rounded text-white"
                >
                  Login
                </button>
              </span>
            </form>
            <div className="d-flex">
              <hr width="50%" />
              <span className="fs-5 px-2">or</span>
              <hr width="50%" />
            </div>
            <div className="pt-3 d-flex justify-content-center gap-3">
              <button type="button" className="border-0 bg-transparent"><i class="bi bi-google"></i></button>
              <button type="button" className="border-0 bg-transparent"><i class="bi bi-facebook"></i></button>
              <button type="button" className="border-0 bg-transparent"><i class="bi bi-apple"></i></button>
            </div>
          </div>
        </div>
        <div className="col-lg-6 d-none d-lg-block h-100">
          <img src={image1} alt="doctors" className="img-fluid" />
        </div>
      </div>
    </div>
  );
};

export default Signin;

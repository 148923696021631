import { BsPinFill } from 'react-icons/bs';

function FaqQuestion({ question, answer }) {
  return (
    <div className="col-md-6 col-lg-4 mb-4">
      <h6 className="mb-3 txt-custom-1">
        <i className="pe-2"><BsPinFill /></i> {question}
      </h6>
      <p>
        <strong>
          <u>{answer}</u>
        </strong>
      </p>
    </div>
  );
}

const Faq = () => {
  const faqData = [
    {
      question: 'What is MEDI-AIDE?',
      answer: 'MEDI-AIDE is a comprehensive doctor-patient app that connects patients with top healthcare professionals, enables online consultations, and provides access to medical records and appointment scheduling.'
    },
    {
      question: 'How can I support MEDI-AIDE?',
      answer: 'You can support MEDI-AIDE by spreading the word about our app to your friends and family, leaving positive reviews on app stores, and providing feedback to help us improve our services.'
    },
    {
      question: 'Who can work at MEDI-AIDE?',
      answer: 'MEDI-AIDE welcomes healthcare professionals such as doctors, specialists, and allied healthcare providers who are licensed and have the necessary qualifications. Please visit our website to learn more about joining our network.'
    },
    {
      question: 'Where is MEDI-AIDE located?',
      answer: 'MEDI-AIDE is a global platform accessible from anywhere. Our services are available internationally, connecting patients and doctors regardless of their geographic location.'
    },
    {
      question: 'When was MEDI-AIDE founded?',
      answer: 'MEDI-AIDE was founded in [year of foundation] with the mission to revolutionize healthcare accessibility and provide a seamless experience for patients and doctors alike.'
    }
  ];

  return (
    <div className="container">
      <section className="my-4">
        <h3 className="text-center my-2 pb-2 text-custom-1 fw-bold">Frequently Asked Questions</h3>
        <p className="text-center mb-5 fs-6">
          Discover answers to the most common questions about MEDI-AIDE.
        </p>
        <div className="row">
          {faqData.map((faq) => (
            <FaqQuestion
              key={faq.question}
              question={faq.question}
              answer={faq.answer}
            />
          ))}
        </div>
      </section>
    </div>
  );
};

export default Faq;

import image from "../../Assets/Images/medi-aide-team.jpg";

const About = () => {
  return (
    <div className="container">
      <div className="text-center">

      </div>
      <div className="mt-5 text-center">
        <h1 className="fs-1 fw-bolder txt-custom-1">Our Team</h1>
        <div className="text-center" style={{ padding: "20px" }}>
          <div style={{ display: "inline-block", maxWidth: "50%" }}>
            <img src={image} alt="profile-pic" className="rounded" style={{ maxWidth: "100%" }} />
          </div>
        </div>

        <p className="text-wrap lh-base lead fs-4 my-2" style={{ textAlign: "justify" }}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac nisl id purus scelerisque semper. Vivamus eget commodo lacus. Ut tempus est id lacus mollis, nec tincidunt risus iaculis. Fusce vel felis aliquet, mollis felis vitae, posuere lectus. In rutrum eros non ex luctus, non varius risus consectetur. Pellentesque lobortis pellentesque ultrices. Integer vulputate malesuada nunc, id bibendum nulla. Nunc suscipit, justo a fermentum ullamcorper, purus sapien rhoncus odio, id commodo sem nulla auctor ex. Maecenas nec feugiat nisl, eu feugiat tellus. Etiam id tempor sem.
        </p>
        <p className="text-wrap lh-base lead fs-4 my-2" style={{ textAlign: "justify" }}>
          Nullam venenatis tristique lorem, vel bibendum velit accumsan ut. Fusce dictum justo in magna tincidunt, a cursus justo euismod. Phasellus pharetra lorem eu dui varius sagittis. Aliquam id vestibulum est. Morbi mollis auctor nunc, eget maximus urna interdum non. Integer scelerisque ligula id nibh fringilla, vitae eleifend metus pellentesque. Vivamus finibus posuere tristique. Phasellus in lacus vel sapien rhoncus vestibulum. Nunc accumsan magna id odio aliquam, id venenatis turpis posuere. Nullam pretium interdum nulla, et congue quam congue et. Proin posuere purus sit amet dui dictum, in sagittis tellus posuere.
        </p>
        <p className="text-wrap lh-base lead fs-4 my-2" style={{ textAlign: "justify" }}>
          Sed consectetur eros sit amet tellus vulputate, a gravida enim placerat. Ut fringilla est in ligula pretium vestibulum. Sed efficitur, lorem ut iaculis interdum, nisi lacus finibus purus, eget congue erat libero nec libero. Quisque vel arcu ac ex volutpat accumsan. Morbi accumsan dapibus sapien. Phasellus lacinia facilisis sollicitudin. Nullam sit amet efficitur risus, id cursus dui. Donec gravida mauris in leo congue, a posuere turpis tristique. In id nisi in tellus egestas sagittis eu eget dolor.
        </p>
      </div>
    </div>
  );
};

export default About;

import React from "react";
import image1 from "../../Assets/Images/design1.svg";
import ladyDesign from "../../Assets/Images/lady-design.svg";
import homeImage2 from "../../Assets/Images/home-img2.svg";
import phonePic from "../../Assets/Images/phone-pic.svg";


const Landing = () => {
  return (
    <div className="container">

      <div style={{ height: "5vh" }}>

      </div>

      {/* section 1 */}
      <div className="row mb-3" style={{ height: "50vh" }}>
        <div className="col-12 col-lg-6 d-flex flex-column justify-content-center">
          <h1 className="display-4 fw-bold mb-2" style={{ fontFamily: 'Alata' }}>
            MEDI-AIDE _ streamlining healthcare services
          </h1>
          <p className="mb-3" style={{ textAlign: "justify", fontFamily: 'Nunito Sans' }}>
            Schedule your first virtual consultation.
          </p>

          <a className="nav-link px-4 py-1 my-1 fw-bold rounded-5 text-white bg-custom-1" href='https://app.medi-aide.com' style={{ height: "50px", width: "150px", display: "flex", alignItems: "center", justifyContent: "center", }}>
            Get started
          </a>

        </div>
        <div className="col-12 col-lg-6">
          <div className="text-center text-lg-start img-box" style={{ height: "50%" }}>
            <img src={image1} alt="doctors" className="img-fluid " />
          </div>
        </div>
      </div>


      <div style={{ height: "50vh" }}>

      </div>




      {/* section 2 */}
      <div className="row">
        <div className="col-12">
          {/* <span className="d-block text-center fw-bold txt-custom-1 text-uppercase fs-5 mb-3">
            Our Services
          </span> */}
          {/* <span className="d-block text-center fw-bold fs-5 mb-3">
            Step by step to manage your healthcare
          </span> */}
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4">
            <div className="col">
              <div className="card border-0 red-card rounded-3">
                <div className="card-body">
                  <span className="d-inline-block bg-custom-red text-white p-3 rounded mb-3">
                    <i class="bi bi-heart-pulse"></i>
                  </span>
                  <div className="card-title fs-5 mb-3 fw-bold" style={{ fontFamily: 'Roboto, sans-serif' }}>
                    Virtual Consultations
                  </div>
                  <div className="card-text pe-5 me-4 fs-6" style={{ textAlign: "justify" }}>
                    Connect with healthcare professionals online for convenient and personalized medical advice from anywhere.
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card border-0 green-card rounded-3">
                <div className="card-body">
                  <span className="d-inline-block bg-custom-1 text-white p-3 rounded mb-3">
                    <i class="bi bi-lungs-fill"></i>
                  </span>
                  <div className="card-title fs-5 mb-3 fw-bold" style={{ fontFamily: 'Roboto, sans-serif' }}>
                    Laboratory Services
                  </div>
                  <div className="card-text pe-5 me-4 fs-6" style={{ textAlign: "justify" }}>
                    Get accurate and timely diagnostic results with our online laboratory testing services.
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card border-0 blue-card rounded-3">
                <div className="card-body">
                  <span className="d-inline-block bg-custom-blue text-white p-3 rounded mb-3">
                    <i class="bi bi-capsule"></i>
                  </span>
                  <div className="card-title fs-5 mb-3 fw-bold" style={{ fontFamily: 'Roboto, sans-serif' }}>
                    Pharmacy Services
                  </div>
                  <div className="card-text pe-5 me-4 fs-6" style={{ textAlign: "justify" }}>
                    Order your medications online and have them delivered to your door for easy access to essential healthcare products.
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card border-0 orange-card rounded-3">
                <div className="card-body">
                  <span className="d-inline-block bg-custom-orange text-white p-3 rounded mb-3">
                    <i class="bi bi-house-heart"></i>
                  </span>
                  <div className="card-title fs-5 mb-3 fw-bold" style={{ fontFamily: 'Roboto, sans-serif' }}>
                    HomeCare Services
                  </div>
                  <div className="card-text pe-5 me-4 fs-6" style={{ textAlign: "justify" }}>
                    Receive professional care and support in the comfort of your own home from experienced caregivers.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ height: "10vh" }}>

      </div>

      {/* section 3 */}
      <div className="row pt-4" >
        <div className="col-12 col-lg-6 d-flex flex-column justify-content-center">

          <h1 className="display-4 fw-bold mb-2" style={{ fontFamily: 'Roboto, sans-serif' }}>
            With Medi-Aide, you can:
          </h1>

          <ul className="mb-3">
            <li className="marker">
              Connect with licensed doctors through virtual consultations
            </li>
          </ul>
          <ul className="mb-3">
            <li className="marker">
              Schedule lab tests with top-notch facilities
            </li>
          </ul>
          <ul className="mb-3">
            <li className="marker">
              Order medications from trusted pharmacies
            </li>
          </ul>
          <ul className="mb-3">
            <li className="marker">
              Arrange personalized home care services for the elderly
            </li>
          </ul>

        </div>
        <div className="col-12 col-lg-6">
          <div className="text-center text-lg-start img-box">
            <img src={ladyDesign} alt="doctors" className="img-fluid h-100" />
          </div>
        </div>
      </div>

      <div style={{ height: "10vh" }}>

      </div>

      {/* section 4 */}
      <div className="row d-flex flex-column-reverse flex-lg-row mt-3 pt-4 mb-3">
        <div className="col-12 col-lg-6">
          <div className="text-center text-lg-start img-box">
            <img src={homeImage2} alt="doctors" className="img-fluid h-100" />
          </div>
        </div>
        <div className="col-12 col-lg-6 d-flex flex-column justify-content-center">

          <h1 className="display-5 fw-bold mb-2" style={{ fontFamily: 'Roboto, sans-serif' }}>
            Experience the benefits of Medi-Aide:
          </h1>

          <ul className="mb-3">
            <li className="marker">Save time: Avoid spending hours in waiting rooms or traveling to appointments</li>
            <li className="marker">Stay safe: Access medical care from the comfort of your home</li>
            <li className="marker">Enjoy peace of mind: Receive quality care from our team of medical professionals</li>
            <li className="marker">Be in control: Manage your healthcare needs effortlessly with our user-friendly app</li>
          </ul>

        </div>
      </div>

      <div style={{ height: "10vh" }}>

      </div>

      <div className="row mt-2 pt-4 mb-3">
        <div className="col-12 col-lg-6 d-flex flex-column justify-content-center">
          {/* <span className="d-block text-uppercase txt-custom-1 mb-2">Download Medi-Aide Now and Transform Your Healthcare Experience!</span> */}
          <h1 className="display-5 fw-bold mb-2" style={{ fontFamily: 'Roboto, sans-serif' }}>
            Join the healthcare revolution today! Download the Medi-Aide app and experience the future of healthcare at your fingertips.
          </h1>
          {/* <p className="mb-3" style={{ textAlign: "justify" }}>
            Join the healthcare revolution today! Download the Medi-Aide app and experience the future of healthcare at your fingertips.
          </p> */}
          {/* <ul className="mb-3">
            <li className="marker">Join the healthcare revolution today! Download the Medi-Aide app and experience the future of healthcare at your fingertips</li>
            <li className="marker">Don't wait any longer! Download Medi-Aide now and enjoy a seamless healthcare experience tailored to your needs</li>
          </ul> */}
          <a className="nav-link px-4 py-1 my-1 fw-bold rounded-5 text-white bg-custom-1" href='/' style={{ height: "50px", width: "200px", display: "flex", alignItems: "center", justifyContent: "center", }}>
            Download now
          </a>

        </div>
        <div className="col-12 col-lg-6">
          <div className="text-center text-lg-start img-box">
            <img src={phonePic} alt="doctors" className="img-fluid h-100" />
          </div>
        </div>
      </div>

      <div style={{ height: "10vh" }}>

      </div>
    </div>
  );
};

export default Landing;
